import React, { Fragment } from "react"
import { Grid, Box, Text, Divider, Button, Image } from "theme-ui"

export const HeroSection = () => {
  return (
    <Fragment>
      <Grid gap={2} columns={[1, "2fr 1fr"]}>
        <Box>
          <Text>
            Backend developer with solid knowledge in server side
            infrastructure, proficient in Python, JavaScript and TypeScript. I
            have experience in building and implementing APIs with Rest or
            Graphql in frameworks like NestJS, NodeJs and Django. I have
            collaborated on frontend projects using React and NextJS. Committed
            to continuous learning and its application in the development of
            innovative solutions.
          </Text>
          <Divider />
          <Box>
            <Button
              mr={2}
              as="a"
              target="_blank"
              href="documents/CVAnderson Pozo-en.pdf"
              variant="primary"
              role="button"
            >
              {" "}
              📃 Download CV
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            p: 2,
          }}
        >
          <Image
            src="../images/perfil.jpg"
            alt="Foto de perfil, Anderson Pozo"
          />
        </Box>
      </Grid>
      <Divider />
    </Fragment>
  )
}
