import React, { Fragment } from "react"
import { HeroSection } from "./HeroSection"
import { About } from "./About"
import { HomeFooter } from "./HomeFooter"

function HomePage() {
  return (
    <Fragment>
      <HeroSection />
      <About />
      <HomeFooter />
    </Fragment>
  )
}

export default HomePage
