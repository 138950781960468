/** @jsx jsx */

import { jsx, Flex, Box, Text, Divider, Link } from "theme-ui"

export default function Footer() {
  let currenteDate = new Date()
  return (
    <Box sx={{ mb: -32 }}>
      <Divider />
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Anderson Pozo © {currenteDate.getFullYear()} | Made in </Text>
        <Flex sx={{ pl: 2 }}>
          <svg
            height="15"
            viewBox="0 0 512 348"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M503.742 174H8.258V256.581H503.742V174Z" fill="#164FCE" />
            <path
              d="M503.742 256.581H8.258V305.579C8.258 324.127 23.293 339.162 41.841 339.162H470.159C488.707 339.162 503.742 324.127 503.742 305.579V256.581V256.581Z"
              fill="#ED1F34"
            />
            <path
              d="M471.26 8.83911H40.74C22.801 8.83911 8.258 23.3821 8.258 41.3211V174H503.742V41.3201C503.742 23.3821 489.199 8.83911 471.26 8.83911Z"
              fill="#FFE000"
            />
            <path
              d="M468.324 0.581055H43.675C19.593 0.581055 0 20.1741 0 44.2571V303.743C0 327.826 19.593 347.419 43.675 347.419H468.323C492.407 347.419 511.999 327.825 511.999 303.743V44.2571C512 20.1741 492.407 0.581055 468.324 0.581055ZM16.516 182.258H495.484V248.323H16.516V182.258ZM43.675 17.0971H468.323C483.3 17.0971 495.483 29.2801 495.483 44.2571V165.742H16.516V44.2571C16.516 29.2801 28.7 17.0971 43.675 17.0971ZM468.324 330.903H43.675C28.699 330.903 16.516 318.72 16.516 303.743V264.839H495.484V303.743C495.484 318.72 483.3 330.903 468.324 330.903Z"
              fill="#121B21"
            />
            <path
              d="M183.264 146.647V205.254C183.264 216.168 192.112 225.016 203.026 225.016H308.973C319.887 225.016 328.735 216.168 328.735 205.254V146.647H183.264V146.647Z"
              fill="#FFE000"
            />
            <path
              d="M308.974 233.275H203.026C187.576 233.275 175.006 220.705 175.006 205.255V146.647C175.006 142.085 178.703 138.389 183.264 138.389H328.735C333.296 138.389 336.993 142.085 336.993 146.647V205.255C336.994 220.705 324.424 233.275 308.974 233.275ZM191.522 154.905V205.255C191.522 211.598 196.683 216.759 203.026 216.759H308.974C315.317 216.759 320.478 211.597 320.478 205.255V154.905H191.522Z"
              fill="#121B21"
            />
            <path
              d="M256 225.013C279.157 225.013 297.929 202.174 297.929 174C297.929 145.826 279.157 122.987 256 122.987C232.843 122.987 214.071 145.826 214.071 174C214.071 202.174 232.843 225.013 256 225.013Z"
              fill="#3DB4EA"
            />
            <path
              d="M256 233.274C228.326 233.274 205.811 206.684 205.811 174C205.811 141.316 228.326 114.726 256 114.726C283.674 114.726 306.189 141.316 306.189 174C306.189 206.684 283.674 233.274 256 233.274ZM256 131.242C237.433 131.242 222.327 150.424 222.327 174C222.327 197.576 237.433 216.758 256 216.758C274.567 216.758 289.673 197.576 289.673 174C289.673 150.424 274.567 131.242 256 131.242Z"
              fill="#121B21"
            />
            <path
              d="M256 216.758C251.439 216.758 247.742 220.454 247.742 225.016V256.581C247.742 261.143 251.439 264.839 256 264.839C260.561 264.839 264.258 261.143 264.258 256.581V225.016C264.258 220.454 260.561 216.758 256 216.758Z"
              fill="#121B21"
            />
            <path
              d="M328.736 93.438H271.415C268.767 93.438 266.28 94.708 264.726 96.854L256 108.903L247.274 96.853C245.72 94.708 243.233 93.437 240.585 93.437H183.264C178.703 93.437 175.006 97.133 175.006 101.695C175.006 106.257 178.703 109.953 183.264 109.953H236.368L249.31 127.826C250.864 129.971 253.351 131.242 255.999 131.242C258.647 131.242 261.134 129.972 262.688 127.826L275.63 109.953H328.735C333.296 109.953 336.993 106.257 336.993 101.695C336.994 97.134 333.297 93.438 328.736 93.438Z"
              fill="#121B21"
            />
          </svg>
        </Flex>
      </Flex>
      <Flex sx={{ justifyContent: "center" }}>
        <Text variant="styles.small">
          Designed by{" "}
          <Link href="https://twitter.com/PaulieScanlon/" target="_blank">
            Paul Scanlon
          </Link>{" "}
          with{" "}
          <Link
            href="https://github.com/PaulieScanlon/gatsby-theme-terminal"
            target="_blank"
          >
            Theme terminal
          </Link>
        </Text>
      </Flex>
    </Box>
  )
}
