import React, { Fragment } from "react"
import { format } from "date-fns"
import { mix } from "@theme-ui/color"
import { Box, Card, Text, Heading, Flex, Badge, Divider } from "theme-ui"
import { SourceList } from "@pauliescanlon/gatsby-theme-terminal"
import CardLinks from "../common/CardLinks"
import Footer from "../common/Footer"

function ProjectsPage() {
  // TODO: Replace projects with an array
  return (
    <Fragment>
      <SourceList filter="projects">
        {(projects) => (
          <Flex
            sx={{
              flexWrap: "wrap",
              ml: (theme) => `-${theme.space[2]}px`,
              mr: (theme) => `-${theme.space[2]}px`,
            }}
          >
            {projects.map((edge, index) => {
              const {
                frontmatter: { title, date, tags, misc, url },
              } = edge.node
              return (
                <Box
                  key={index}
                  sx={{
                    mb: 3,
                    maxWidth: ["100%", "50%", "33.333%"],
                    width: ["100%", "50%", "33.333%"],
                  }}
                >
                  <Card
                    sx={{
                      ml: 2,
                      mr: 2,
                      p: 3,
                    }}
                  >
                    <Text sx={{ fontSize: 0, color: "muted" }}>
                      {format(new Date(date), "d-MMM-u")}
                    </Text>
                    <Heading variant="styles.h2" color="text">
                      {title}
                    </Heading>
                    {tags.map((tag, index) => {
                      return (
                        <Badge
                          key={index}
                          variant="primary"
                          sx={{
                            mr: 2,
                            mb: 2,
                            color: mix(
                              "success",
                              "secondary",
                              `${index / tags.length}`
                            ),
                            borderColor: mix(
                              "success",
                              "secondary",
                              `${index / tags.length}`
                            ),
                          }}
                        >
                          {tag}
                        </Badge>
                      )
                    })}
                    <CardLinks repo={misc} demo={url} />
                  </Card>
                </Box>
              )
            })}
          </Flex>
        )}
      </SourceList>
      <Divider />
      <Footer />
    </Fragment>
  )
}

export default ProjectsPage
