import React, { Fragment } from "react"
import { Divider, Heading } from "theme-ui"
import { Link } from "gatsby"

export const About = () => {
  return (
    <Fragment>
      <Heading>About me</Heading>
      <p>
        Fanatic of literature, TV series and sports, I'm a quiet person with a
        good sense of humor. In my free time I like to meditate, walk and I also
        write programming tutorials on my <Link to="/posts/">blog</Link>, I
        invite you to check it out and give me your opinion.
      </p>
      <Divider />
    </Fragment>
  )
}
