import React, { Fragment } from "react"
import { format } from "date-fns"
import { mix } from "@theme-ui/color"
import {
  Box,
  Card,
  Text,
  Heading,
  Flex,
  Badge,
  Divider,
  Link,
  Image,
} from "theme-ui"
import { SourceList } from "@pauliescanlon/gatsby-theme-terminal"
import Footer from "../common/Footer"

function BlogPage() {
  return (
    <Fragment>
      <SourceList filter="posts">
        {(posts) => (
          <Flex
            sx={{
              flexWrap: "wrap",
              ml: (theme) => `-${theme.space[2]}px`,
              mr: (theme) => `-${theme.space[2]}px`,
            }}
          >
            {posts.map((edge, index) => {
              const {
                frontmatter: { title, date, tags, author, featuredImageUrl },
                fields: { slug },
              } = edge.node
              return (
                <Box
                  key={index}
                  sx={{
                    mb: 3,
                    maxWidth: ["100%", "50%"],
                    width: ["100%", "50%"],
                  }}
                >
                  <Link href={slug} sx={{ textDecoration: "none" }}>
                    <Card
                      sx={{
                        ml: 2,
                        mr: 2,
                        p: 0,
                      }}
                    >
                      <Image src={featuredImageUrl} />
                      <Box p={3}>
                        <Text sx={{ fontSize: 0, color: "primary" }}>
                          ({format(new Date(date), "d-MMM-u")}, {author})
                        </Text>
                        <Heading variant="styles.h2" color="text">
                          {title}
                        </Heading>
                        {tags.map((tag, index) => {
                          return (
                            <Badge
                              key={index}
                              variant="primary"
                              sx={{
                                mr: 2,
                                mb: 2,
                                color: mix(
                                  "success",
                                  "secondary",
                                  `${index / tags.length}`
                                ),
                                borderColor: mix(
                                  "success",
                                  "secondary",
                                  `${index / tags.length}`
                                ),
                              }}
                            >
                              {tag}
                            </Badge>
                          )
                        })}
                      </Box>
                    </Card>
                  </Link>
                </Box>
              )
            })}
          </Flex>
        )}
      </SourceList>
      <Divider />
      <Footer />
    </Fragment>
  )
}

export default BlogPage
