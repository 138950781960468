import React, { Fragment } from "react"
import Footer from "../../common/Footer"
import SocialNetworkButtons from "../../common/SocialNetworkButtons"
import { Box, Heading, Badge, Divider, Grid } from "theme-ui"

export const HomeFooter = () => {
  const skills = [
    { id: 1, name: "Python", color: "primary" },
    { id: 2, name: "Django", color: "success" },
    { id: 3, name: "Docker", color: "secondary" },
    { id: 4, name: "Git", color: "primary" },
    { id: 5, name: "GitHub", color: "success" },
    { id: 6, name: "Node JS", color: "secondary" },
    { id: 7, name: "JavaScript", color: "primary" },
    { id: 8, name: "TypeScript", color: "success" },
  ]
  return (
    <Fragment>
      <Grid gap={2} columns={[1, "1fr 1fr"]}>
        <Box>
          <Heading>Skills</Heading>
          {skills.map(({ id, name, color }) => (
            <Badge key={id} variant={color} mb={2} mr={2}>
              {name}
            </Badge>
          ))}
        </Box>
        <Box>
          <Heading>Contact me</Heading>
          <SocialNetworkButtons />
        </Box>
      </Grid>
      <Divider />
      <Footer />
    </Fragment>
  )
}
